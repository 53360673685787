import { useState } from 'react';
import Letter from './components/Letter/Letter';

const App = () => {
    const [letters, setLetters] = useState(['L', 'U', 'U', 'D']);

    document.onclick = () => {
        setLetters(letters.concat(['L', 'U', 'U', 'D']));
    };

    return (
        <>
            {letters.map((letter) => <Letter letter={letter} />)}
        </>
    );
}

export default App;
