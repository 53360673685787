import { useState, useEffect, useCallback } from 'react';
import styles from './Letter.module.scss';

const COLOR_MAP = {
    'white': '#F7FFEC',
    'green-light': '#ABFEAF',
    'green-medium': '#56FEA0',
    'blue-light': '#02FADB',
    'blue-medium': '#03A0E6',
    'blue-dark': '#05318E',
    'blue-darkest': '#021C61',
    'pink-light': '#FF4085',
    'pink-medium': '#FE247C',
    'pink-dark': '#C31079',
    'orange': '#EE9088',
};

const COLORS = Object.keys(COLOR_MAP);

const MIN = 750;
const MAX = 5000;

const MIN_POS = 10;
const MAX_POS = 80;

let TIMEOUT;

const Letter = ({ letter = '*' }) => {

    const [tDuration, setTDuration] = useState(parseInt(Math.random() * (MAX - MIN) + MIN, 10));
    const [left, setLeft] = useState(parseInt(Math.random() * (MAX_POS - MIN_POS) + MIN_POS, 10));
    const [top, setTop] = useState(parseInt(Math.random() * (MAX_POS - MIN_POS) + MIN_POS, 10));
    const [color, setColor] = useState(COLORS[parseInt(Math.random() * COLORS.length, 10)]);

    const update = useCallback((init = false) => {
        const timeoutTime = init ? 1 : parseInt(Math.random() * (MAX - MIN) + MIN, 10);
        TIMEOUT = setTimeout(() => {
            setColor(COLORS[parseInt(Math.random() * COLORS.length, 10)]);
            setLeft(parseInt(Math.random() * (MAX_POS - MIN_POS) + MIN_POS, 10));
            setTop(parseInt(Math.random() * (MAX_POS - MIN_POS) + MIN_POS, 10));
            setTDuration(Math.min(init ? MAX : timeoutTime, parseInt(Math.random() * (MAX - MIN) + MIN, 10)));
            update();
        }, timeoutTime);
    }, []);

    useEffect(() => {
        update(true);
        return () => {
            clearTimeout(TIMEOUT);
        }
    }, [update]);

    return (
        <span
            className={styles.letter}
            style={{
                color: COLOR_MAP[color],
                transitionDuration: `${tDuration}ms`,
                left: `${left}vw`,
                top: `${top}vh`,
            }}
        >{letter}</span>
    );

};

export default Letter;
